import React, { useState } from 'react';
import BookItem from './BookItem';
import BookModal from './BookModal';

const BookGrid = ({ books }) => {
	const [selectedBook, setSelectedBook] = useState(null);

	const openModal = (book) => {
		setSelectedBook(book);
	};

	const closeModal = () => {
		setSelectedBook(null);
	};

	return (
		<>
			<div className="grid grid-cols-2 sm:grid-cols-5 lg:grid-cols-8 gap-4 items-start">
				{books.map((book, index) => (
					<BookItem key={index} book={book} onClick={() => openModal(book)} />
				))}
			</div>
			<BookModal book={selectedBook} onClose={closeModal} />
		</>
	);
};

export default BookGrid;