import React from 'react';

const SearchBar = ({ searchTerm, handleSearchChange }) => {
	return (
		<input
			type="text"
			placeholder="本を検索..."
			value={searchTerm}
			onChange={handleSearchChange}
			className="p-2 border rounded w-full md:w-96"
		/>
	);
};

export default SearchBar;