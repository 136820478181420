export const books = [
	{
		title: 'デジタル・ブランディング――世界のトップブランドがいま実践していること',
		author: 'パブロ・ルビオ・オルダス',
		asin: 'B0BKPQ8NB2',
		tag: ['ブランディング', 'デジタル'],
		image: 'https://m.media-amazon.com/images/I/71ljPfB-saL._SY522_.jpg',
		url: {
			amazon: 'https://amzn.to/4hDTg6W',
		},
		publishedDate: '2022-10-28',
	},
	{
		title: 'ABOUT FACE インタラクションデザインの本質（第四版）',
		author: 'Alan Cooper、Robert Reimann、David Cronin、Christopher Noessel',
		asin: 'B0D7FS5966',
		tag: ['インタラクション'],
		image: 'https://m.media-amazon.com/images/I/712COn0c3CL._SL1500_.jpg',
		url: {
			amazon: 'https://amzn.to/3UMRvKZ',
		},
		publishedDate: '2024-08-14',
	},
	{
		title: 'みんなではじめるデザイン批評―目的達成のためのコラボレーション&コミュニケーション改善ガイド',
		author: 'アーロン・イリザリー（Aaron Irizarry）、アダム・コナー（Adam Connor）',
		asin: 'B01J2OEYLU',
		tag: ['デザインレビュー'],
		image: 'https://m.media-amazon.com/images/I/71oVKpSV9VL._SL1280_.jpg',
		url: {
			amazon: 'https://amzn.to/3Z1pMsy',
		},
		publishedDate: '2016-07-26',
	},
	{
		title: 'オンスクリーン タイポグラフィ 事例と論説から考えるウェブの文字表現',
		author: '伊藤 庄平、佐藤 好彦、守友 彩子、桝田 草一、カワセ タケヒロ、ハマダ ナオフミ、きむ みんよん、関口 浩之、生明 義秀',
		asin: 'B08WCGDS3C',
		tag: ['タイポグラフィ'],
		image: 'https://m.media-amazon.com/images/I/81ozQ3aR7uL._SL1500_.jpg',
		url: {
			amazon: 'https://amzn.to/48Omw75',
			rakuten: 'https://a.r10.to/hPzxtq'
		},
		publishedDate: '2021-02-17',
	},
	{
		title: 'より良い世界のためのデザインー意味、持続可能性、人間性中心',
		author: 'D・ノーマン（ドナルド・ノーマン）',
		asin: '4788518279',
		tag: ['タイポグラフィ'],
		image: 'https://m.media-amazon.com/images/I/81hYNSZ2CRL._SL1500_.jpg',
		url: {
			amazon: 'https://amzn.to/4fLHG7U',
		},
		publishedDate: '2023-10-12',
	},
	{
		title: 'はじめてのUIデザイン 改訂版',
		author: '池田 拓司、宇野 雄、上ノ郷谷 太一、坪田 朋、元山 和之、吉竹 遼',
		asin: 'B087BMJ1Q6',
		tag: ['UI'],
		image: 'https://m.media-amazon.com/images/I/81rpWqwfAJL._SL1500_.jpg',
		url: {
			amazon: 'https://amzn.to/3Z49RJY',
		},
		publishedDate: '2020-05-01',
	},
	{
		title: '融けるデザイン ―ハード×ソフト×ネット時代の新たな設計論',
		author: '渡邊 恵太',
		asin: 'B00TPLKYEM',
		tag: ['UI', 'インタラクション'],
		image: 'https://m.media-amazon.com/images/I/71-R9+XyvfL._SL1500_.jpg',
		url: {
			amazon: 'https://amzn.to/3Z82hy7',
		},
		publishedDate: '2015-02-18',
	},
	{
		title: '縁の下のUIデザイン──小さな工夫で大きな効果をもたらす実践TIPS＆テクニック',
		author: '池田 拓司',
		asin: 'B0C1MZ8NLB',
		tag: ['UI'],
		image: 'https://m.media-amazon.com/images/I/715STra2LqL._SL1500_.jpg',
		url: {
			amazon: 'https://amzn.to/3O7kpBV',
		},
		publishedDate: '2023-04-21',
	},
	{
		title: '日本語組版入門: その構造とアルゴリズム',
		author: '向井 裕一',
		asin: '4416618689',
		tag: ['タイポグラフィ'],
		image: 'https://m.media-amazon.com/images/I/71LBN7odFgL._SL1200_.jpg',
		url: {
			amazon: 'https://amzn.to/40W5v9f',
		},
		publishedDate: '2018-06-08',
	},
	{
		title: 'タイポグラフィの基礎―知っておきたい文字とデザインの新教養',
		author: '小宮山 博史',
		asin: '441661022X',
		tag: ['タイポグラフィ'],
		image: 'https://m.media-amazon.com/images/I/91W85UHIoXL._SL1500_.jpg',
		url: {
			amazon: 'https://amzn.to/4hRb6n0',
		},
		publishedDate: '2010-08-01',
	},
	{
		title: '欧文書体―その背景と使い方 (新デザインガイド)',
		author: '小林 章',
		asin: '4568502772',
		tag: ['タイポグラフィ'],
		image: 'https://m.media-amazon.com/images/I/41OKbfK2WQL.jpg',
		url: {
			amazon: 'https://amzn.to/48VIvJf',
		},
		publishedDate: '2005-06-16',
	},
	{
		title: '欧文書体 2 定番書体と演出法 (タイポグラフィの基本BOOK)',
		author: '小林 章',
		asin: '4568503647',
		tag: ['タイポグラフィ'],
		image: 'https://m.media-amazon.com/images/I/41U7YgVpVwL.jpg',
		url: {
			amazon: 'https://amzn.to/4eutkrP',
		},
		publishedDate: '2005-06-16',
	},
];