import React, { useEffect, useState } from 'react';
import { LiaTimesSolid } from "react-icons/lia";

const BookModal = ({ book, onClose }) => {
	const [isVisible, setIsVisible] = useState(true);
	const [isClosing, setIsClosing] = useState(false);
	const [scrollPosition, setScrollPosition] = useState(0);

	const handleClose = () => {
		setIsClosing(true);
		setTimeout(() => {
			onClose();
			window.scrollTo(0, scrollPosition); // スクロール位置を戻す
		}, 300); // アニメーションの時間に合わせて遅延させる
	};

	useEffect(() => {
		if (!book) {
			setIsClosing(false);
			setIsVisible(false);
			document.body.style.overflow = 'auto'; // スクロールを有効にする
		} else {
			setIsVisible(true);
			setScrollPosition(window.scrollY); // 現在のスクロール位置を記録
			document.body.style.overflow = 'hidden'; // スクロールを無効にする
		}
	}, [book]);

	if (!isVisible) return null;

	if (!book) {
		return null; // bookがnullの場合は何も表示しない
	}

	return (
		<div className={`fixed inset-0 flex items-end justify-center bg-black bg-opacity-50 transition-all duration-300 ease-in-out md:items-center ${isClosing ? 'opacity-0' : 'opacity-100 backdrop-blur'}`} onClick={handleClose}>
			<div className={`bg-white p-6 h-[95vh] rounded-t-lg shadow-lg w-full max-w-md transform transition-transform duration-300 md:h-auto md:rounded-lg ${isClosing ? 'translate-y-full animate-slide-down' : 'translate-y-0 animate-slide-up'}`} onClick={(e) => e.stopPropagation()}>
				<button onClick={handleClose} className="absolute p-4 top-0 right-0 text-gray-500 hover:text-gray-700">
					<LiaTimesSolid size={20} />
				</button>
				<div className="overflow-y-auto h-full pb-4">
					<img src={book.image} alt={book.title} className="w-full max-w-40 mb-4 mx-auto drop-shadow-md" />
					<h2 className="text-base font-bold">{book.title}</h2>
					<p className="text-sm text-gray-600">{book.author}</p>
					<p className="text-sm text-gray-600 mb-4">発売日：{book.publishedDate}</p>
					<div className="flex space-x-2">
						{book.url.amazon && (
							<a href={book.url.amazon} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white px-4 py-2 rounded-md">
								Amazon
							</a>
						)}
						{book.url.rakuten && (
							<a href={book.url.rakuten} target="_blank" rel="noopener noreferrer" className="bg-red-500 text-white px-4 py-2 rounded-md">
								楽天
							</a>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default BookModal; 