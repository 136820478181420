import React from 'react';
import { FaList, FaTh } from 'react-icons/fa';

const ViewToggle = ({ isGridView, setIsGridView }) => {
	return (
		<button
			onClick={() => setIsGridView(!isGridView)}
			className="top-4 right-4 p-2 flex items-center"
		>
			{isGridView ? (
				<>
					<FaList className="mr-1" />
					リスト表示
				</>
			) : (
				<>
					<FaTh className="mr-1" />
					グリッド表示
				</>
			)}
		</button>
	);
};

export default ViewToggle;