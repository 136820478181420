import React, { useState } from 'react';
import BookModal from './BookModal';

const BookList = ({ books }) => {
	const [selectedBook, setSelectedBook] = useState(null);

	const openModal = (book) => {
		setSelectedBook(book);
	};

	const closeModal = () => {
		setSelectedBook(null);
	};

	return (
		<>
			<ul className="list-none pl-0">
				{books.map((book, index) => (
					<li key={index} className="flex items-center border-b border-gray-200 py-4">
						<button
							onClick={() => openModal(book)}
							className="flex items-center w-full text-left"
						>
							<img src={book.image} alt={book.title} className="w-16 h-24 object-cover mr-4 shadow-md" />
							<div className="flex-grow">
								<h3 className="font-bold line-clamp-2">{book.title}</h3>
								<p className="text-sm text-gray-600">{book.author}</p>
								<p className="text-xs text-gray-500">{book.publishedDate}</p>
								<div className="flex flex-wrap mt-1">
									{book.tag.map((tag, tagIndex) => (
										<span key={tagIndex} className="bg-gray-200 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
											{tag}
										</span>
									))}
								</div>
							</div>
						</button>
					</li>
				))}
			</ul>
			<BookModal book={selectedBook} onClose={closeModal} />
		</>
	);
};

export default BookList; 