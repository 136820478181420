import React from 'react';

const BookItem = ({ book, onClick }) => {
	return (
		<button onClick={onClick} className="text-left w-full">
			<img src={book.image} alt={book.title} className="w-full mb-2 shadow-lg" />
			<div>
				<h3 className="text-sm font-bold mb-1 line-clamp-2">{book.title}</h3>
				<p className="text-xs text-gray-600">{book.author}</p>
			</div>
		</button>
	);
};

export default BookItem;