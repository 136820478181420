import React, { useState, useEffect } from 'react';
import { books } from '../../data';
import SortDropdown from './SortDropdown';
import BookGrid from './BookGrid';
import SearchBar from './SearchBar';
import ViewToggle from './ViewToggle';
import BookList from './BookList';

const Main = () => {
	const [isGridView, setIsGridView] = useState(true);
	const [sortedBooks, setSortedBooks] = useState([]);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [sortOption, setSortOption] = useState('出版日が新しい順');
	const [searchTerm, setSearchTerm] = useState('');

	useEffect(() => {
		sortByDate('新しい');
	}, []);

	const sortByDate = (order) => {
		const sorted = [...books].sort((a, b) => {
			return order === '新しい'
				? new Date(b.publishedDate) - new Date(a.publishedDate)
				: new Date(a.publishedDate) - new Date(b.publishedDate);
		});
		setSortedBooks(sorted);
	};

	const handleSortOptionChange = (option) => {
		setSortOption(option);
		setIsDropdownOpen(false);
		sortByDate(option === '出版日が新しい順' ? '新しい' : '古い');
	};

	const handleSearchChange = (event) => {
		const value = event.target.value;
		setSearchTerm(value);
		const filteredBooks = books.filter(book =>
			book.title.includes(value)
		);
		setSortedBooks(filteredBooks);
	};

	return (
		<main className="flex-grow p-4 relative">
			<div className="flex flex-col md:flex-row justify-between mb-4">
				<SearchBar searchTerm={searchTerm} handleSearchChange={handleSearchChange} />
				<div className="flex justify-end">
					<ViewToggle isGridView={isGridView} setIsGridView={setIsGridView} />
					<SortDropdown
						isDropdownOpen={isDropdownOpen}
						toggleDropdown={() => setIsDropdownOpen(!isDropdownOpen)}
						sortOption={sortOption}
						handleSortOptionChange={handleSortOptionChange}
					/>
				</div>
			</div>
			<section id="book-list">
				{isGridView ? (
					<BookGrid books={sortedBooks} />
				) : (
					<BookList books={sortedBooks} />
				)}
			</section>
		</main >
	);
};

export default Main;
