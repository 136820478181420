import React from 'react';

const SortDropdown = ({ isDropdownOpen, toggleDropdown, sortOption, handleSortOptionChange }) => {
	return (
		<div className="relative">
			<button
				onClick={toggleDropdown}
				className="ml-2 p-2 flex items-center"
			>
				<span className="mr-1">
					↑↓
				</span>
				<span>{sortOption}</span>
			</button>
			{isDropdownOpen && (
				<div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded">
					<ul>
						<li onClick={() => handleSortOptionChange('出版日が新しい順')} className="p-2 hover:bg-gray-200 cursor-pointer">出版日が新しい順</li>
						<li onClick={() => handleSortOptionChange('出版日が古い順')} className="p-2 hover:bg-gray-200 cursor-pointer">出版日が古い順</li>
					</ul>
				</div>
			)}
		</div>
	);
};

export default SortDropdown;