import React from 'react';

const Header = () => {
	return (
		<header className="flex justify-between items-center py-4 px-6 bg-white shadow-md">
			<h1 className="text-xl font-bold">
				<a href="/">
					Design Book Collection
				</a>
			</h1>
			<nav>
				<ul className="flex space-x-4 text-sm">
					<li><a className="hover:underline" href="/about">このサイトについて</a></li>				</ul>
			</nav>
		</header>
	);
};

export default Header;
